import React from 'react';
import { graphql } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';

import SEO from '../components/seo';
import Layout from '../components/layout';

const serializers = {
  types: {
    code: ({ node }) => (
      <pre data-language={node.language}>
        <code>{node.code}</code>
      </pre>
    ),
  },
};

const Page = ({ data }) => {
  const { sanityPage } = data;
  const title = sanityPage?.title || '';
  const description = sanityPage?.description || '';
  const hasDate = !!sanityPage?.date;
  const date = new Date(sanityPage?.date);
  const displayDate = hasDate ? date.toLocaleDateString('en-GB', {}) : '-';
  const category = sanityPage?.category?.title || '';
  const author = sanityPage?.author;
  const displayAuthor = `${author?.name} ${author?.surname}`;
  return (
    <Layout>
      <SEO title={title} description={description} />
      <h1 className="text-2xl mb-2">{title}</h1>
      <div className="mb-4">
        <div className="uppercase tracking-wide text-sm text-indigo-600 font-bold">
          {category}
        </div>
        <div className="text-gray-600">{displayAuthor}</div>
        <div className="text-gray-600 text-sm">{displayDate}</div>
      </div>
      <BlockContent
        blocks={sanityPage?._rawContent}
        serializers={serializers}
      />
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    sanityPage(slug: { current: { eq: $slug } }) {
      title
      date
      description
      category {
        title
      }
      author {
        name
        surname
      }
      _rawContent
    }
  }
`;

export default Page;
